import type {
  UseAsyncStateOptions,
  UseAsyncStateReturnBase,
} from '@vueuse/core'
import type { ComputedRef, ShallowRef } from 'vue'

/**
 * @description extended from the options of useAsyncState in vueuse
 * @see https://github.com/vueuse/vueuse/blob/31b66263163b0c39015f000b91a221e19602b171/packages/core/useAsyncState/index.ts#L17
 * @interface FetchStateOptions
 * @extends {UseAsyncStateOptions<true, any>}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FetchStateOptions extends UseAsyncStateOptions<true, any> {
  /** Cache duration,
   * @default 15000
   * */
  interval?: number
  /** when call fetchState, ignore last cache, this is useful for using fetchState in traversals
   * @default false
   * */
  force?: boolean
}
export interface CacheMapItem<
  Data,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Params extends any[],
  Shallow extends boolean,
> {
  timestamp: number
  value:
    | (Omit<UseAsyncStateReturnBase<Data, Params, Shallow>, 'execute'> & {
        refresh: (force?: boolean) => Promise<Data>
      })
    | undefined
}

const defaultUseAsyncStateOptions = {
  onError: (e: unknown) => {
    console.error(e)
  },
}
// TODO: Type optimization

/**
 * @deprecated please use `useCacheableAsyncData` instead.
 * @description Cache the data of the interface calls in the store, and the interface calls with different parameters will be cached separately.
 * @export
 * @param {(...args: any[]) => Promise<any>} request request callback
 * @param {*} initialState
 * @return {*}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useAsyncCache<Data, Params extends any[] = []>(
  request: (...args: Params) => Promise<Data>,
  initialState: Data,
) {
  const cacheMap = reactive(new Map<string, CacheMapItem<Data, Params, true>>())

  /**
   * @description Use params as the key to set the value returned by the interface
   * @param {(Params | undefined)} params
   * @param {unknown} value return value
   * @param {number} [timestamp=Date.now()] Interface call timestamp, used to compare with interval to control whether to re-acquire
   * @return {*} return cached value
   */
  const setState = (
    params: Params | undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    interval: number,
    timestamp: number = Date.now(),
  ) => {
    const { state, isReady, isLoading, error, execute } = value
    cacheMap.set(generateKey(params), {
      timestamp,
      value: {
        state,
        isReady,
        isLoading,
        error,
        // Get the cached value first, if you don't want to use the cached value, you need to pass `force=true`
        refresh: (force = false) => {
          const oldState = cacheMap.get(generateKey(params))
          if (!oldState) return
          if (!force && Date.now() < oldState.timestamp + interval) return
          // TODO: need add back options to execute?
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return execute().then((res: any) => {
            setTimeout(() => {
              const state = getState(...(params || ([] as unknown as Params)))
              cacheMap.set(generateKey(params), {
                timestamp: Date.now(),
                value: state.value as NonNullable<typeof state.value>,
              })
            })
            return res
          })
        },
      },
    })
    const newState = getState(...(params || ([] as unknown as Params)))
    return newState as ComputedRef<NonNullable<typeof newState.value>>
  }

  /**
   * @description Call the interface, return the cached data, if the call interval is greater than the interval, it will be re-acquired
   * @param {Params} [args] request params
   * @return {*} Return value type reference CacheMapItem
   */
  const fetchState = (
    args = [] as unknown as Params,
    options?: FetchStateOptions,
  ) => {
    const {
      interval = 15000,
      force = false,
      ...useAsyncStateOptions
    } = options || {}

    const state = cacheMap.get(generateKey(args))

    if (
      !force &&
      state !== undefined &&
      (Date.now() < state.timestamp + interval || options?.immediate === false)
    ) {
      // return cached value
      return fetchStateReturnWrap(computed(() => state.value!))
    }
    // call request
    const timestamp =
      useAsyncStateOptions?.immediate === false
        ? 0
        : (useAsyncStateOptions?.delay || 0) + Date.now()

    return fetchStateReturnWrap(
      setState(
        args,
        useAsyncState(() => request(...args), initialState, {
          ...defaultUseAsyncStateOptions,
          ...useAsyncStateOptions,
        }),
        interval,
        timestamp,
      ),
    )
  }

  const fetchStateReturnWrap = (result: ReturnType<typeof setState>) => {
    return {
      ...toRefs(result.value),
      refresh: result.value.refresh,
    }
  }

  /**
   * @description Use args as the key to get the data in the cache
   * @param {Params} [args]
   * @notice When fetchState is not called, return Computed<undefined>
   */
  const getState = (...args: Params) => {
    const key = generateKey(args)
    return computed(() => cacheMap.get(key)?.value)
  }

  /**
   * @description Refresh all cacheMap data
   * @param {boolean} [force=false]
   */
  function refreshAllState(force = false) {
    cacheMap.forEach((state) => {
      state.value?.refresh?.(force)
    })
  }

  /**
   * @description Generate Map key through params
   * @param {Params} [params]
   * @return { string } key
   */
  const generateKey = (params?: Params) => {
    return 'key_' + JSON.stringify(params)
  }

  /**
   * @description Get all the values in the cacheMap and return them as an array
   */
  const getCaches = () => {
    return computed(() => {
      const caches = [] as (Data | undefined)[]
      cacheMap.forEach((x) => {
        caches.push(x.value?.state)
      })
      return caches
    })
  }

  return {
    /** @deprecated please use `useCacheableAsyncData` instead. */
    cacheMap,
    /** @deprecated please use `useCacheableAsyncData` instead. */
    fetchState,
    /** @deprecated please use `useCacheableAsyncData` instead. */
    getState,
    /** @deprecated please use `useCacheableAsyncData` instead. */
    refreshAllState,
    /** @deprecated please use `useCacheableAsyncData` instead. */
    getCaches,
  }
}
